import { all, takeEvery, put, call } from 'redux-saga/effects'

import * as services from 'services/area'
import actions from './actions'

export function* LIST({ payload }) {
  yield put({
    type: 'area/SET_STATE',
    payload: {
      loading: true,
    },
  })
  console.log(payload)
  const response = yield call(services.list, payload)
  if (response) {
    yield put({
      type: 'area/SET_STATE',
      payload: {
        data: response,
      },
    })
  }
  yield put({
    type: 'area/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LIST, LIST)])
}
