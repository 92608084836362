import apiClient from 'services/axios'

export async function login(email, password) {
  return apiClient.get('../sanctum/csrf-cookie').then(() => {
    return apiClient
      .post('/login', {
        email,
        password,
      })
      .then((response) => {
        if (response) {
          return true
        }
        return false
      })
      .catch((err) => console.log(err))
  })
}

export async function update(payload) {
  return apiClient
    .put('/account', payload)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/account')
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function logout() {
  return apiClient
    .post('/logout')
    .then(() => {
      return true
    })
    .catch((err) => console.log(err))
}
