import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as services from 'services/user'
import actions from './actions'

export function* LIST({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.list, payload)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        data: response,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LIST_JOB_TITLE() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.jobTitle)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        job_title: response,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* STORE({ payload, onSuccess }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.store, payload)
  if (response) {
    yield put({
      type: 'user/LIST',
    })
    notification.success({
      message: 'Create User Success',
    })
    onSuccess()
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE({ payload, onSuccess }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.update, payload)
  if (response) {
    yield put({
      type: 'user/LIST',
    })
    notification.success({
      message: 'Update User Success',
    })
    onSuccess()
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_PASSWORD({ payload, onSuccess }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.updatePassword, payload)
  if (response) {
    yield put({
      type: 'user/LIST',
    })
    notification.success({
      message: 'Update Password User Success',
    })
    onSuccess()
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DESTROY({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.destroy, payload)
  if (response) {
    yield put({
      type: 'user/LIST',
    })
    notification.success({
      message: 'Delete User Success',
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LIST_SELECT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.list, payload)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        selectData: response.map((user) => ({
          value: user.id,
          label: user.email,
        })),
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.LIST_SELECT, LIST_SELECT),
    takeEvery(actions.LIST_JOB_TITLE, LIST_JOB_TITLE),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.UPDATE_PASSWORD, UPDATE_PASSWORD),
    takeEvery(actions.STORE, STORE),
    takeEvery(actions.DESTROY, DESTROY),
  ])
}
