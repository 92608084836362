import apiClient from 'services/axios'

export async function list() {
  return apiClient
    .get('/batch')
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function store(payload) {
  const formData = new FormData()
  formData.append('type', payload.type)
  console.log(payload.file)
  formData.append('file', payload.file.file.originFileObj)
  return apiClient
    .post('/batch', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function update(payload) {
  return apiClient
    .put(`/batch/${payload.id}`, payload)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}
