import React from 'react'
import style from './style.module.scss'

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <p className="mb-0">
          Copyright © {new Date().getFullYear()}{' '}
          <a href="#" target="_blank" rel="noopener noreferrer">
            {process.env.REACT_APP_BY}
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
