export default async function getMenuData() {
  return [
    // VB:REPLACE-START:MENU-CONFIG
    {
      title: 'Dashboard',
      key: '__dashboard',
      url: '/dashboard',
      icon: 'fe fe-home',
    },
    {
      title: 'Job',
      key: '__job',
      category: true,
    },
    {
      title: 'Activity',
      key: '__activity',
      url: '/activity',
      icon: 'fe fe-briefcase',
      permission: 'admin activity',
    },
    {
      title: 'Attendance',
      key: '__attendance',
      url: '/attendance',
      icon: 'fe fe-calendar',
      permission: 'admin attendance',
    },
    {
      title: 'Purchase Order',
      key: '__purchase-order',
      url: '/purchase-order',
      icon: 'fe fe-shopping-cart',
      permission: 'admin purchase',
    },
    {
      title: 'Target',
      key: '__target',
      url: '/target',
      icon: 'fe fe-crosshair',
      permission: 'admin purchase',
    },
    {
      title: 'Plant',
      key: '__plant',
      url: '/plant',
      icon: 'icmn-leaf',
      permission: 'admin plant',
    },
    {
      title: 'Pest',
      key: '__pest',
      url: '/pest',
      icon: 'icmn-bug',
      permission: 'admin pest',
    },
    {
      title: 'Product',
      key: '__product',
      url: '/product',
      icon: 'fe fe-box',
      permission: 'admin product',
    },
    {
      title: 'Client',
      key: '__client',
      category: true,
    },
    {
      title: 'Kiosk',
      key: '__customer',
      url: '/customer',
      icon: 'fe fe-user',
      permission: 'admin customer',
    },
    {
      title: 'Contact',
      key: '__contact',
      url: '/contact',
      icon: 'fe fe-bookmark',
      permission: 'admin contact',
    },
    {
      title: 'Admin',
      key: '__admin',
      category: true,
    },
    {
      title: 'Batch',
      key: '__batch',
      url: '/batch',
      icon: 'fe fe-archive',
      permission: 'admin batch',
    },
    {
      title: 'User',
      key: '__user',
      url: '/user',
      icon: 'fe fe-users',
      permission: 'admin user',
    },
    {
      title: 'Role',
      key: '__role',
      url: '/role',
      icon: 'fe fe-unlock',
      permission: 'admin role',
    },

    // VB:REPLACE-END:MENU-CONFIG
  ]
}
