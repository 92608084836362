import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: '/iapi',
  withCredentials: true,
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use((request) => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, (error) => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data && data.message) {
    if (data.errors) {
      Object.keys(data.errors).forEach((key) => {
        notification.warning({
          message: (data.errors[key] || []).join(', ').replace('-1', 'reject'),
        })
      })
    } else {
      notification.warning({
        message: data.message,
      })
    }
  } else {
    notification.error({
      message: 'Please contact IT!',
    })
  }
})

apiClient.get('../sanctum/csrf-cookie')

export default apiClient
