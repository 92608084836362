import apiClient from 'services/axios'

export async function list(payload) {
  return apiClient
    .get('/customer', { params: payload })
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function store(payload) {
  return apiClient
    .post('/customer', payload)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function update(payload) {
  return apiClient
    .put(`/customer/${payload.id}`, payload)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function download() {
  return apiClient
    .post('/customer/download', null, { responseType: 'blob' })
    .then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const fileNameHeader = 'content-disposition'
        const suggestedFileName = response.headers[fileNameHeader]
          .split('filename=')[1]
          .split(';')[0]

        link.setAttribute('download', suggestedFileName)
        document.body.appendChild(link)
        link.click()

        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}
