import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import account from './account/reducers'
import user from './user/reducers'
import menu from './menu/reducers'
import permission from './permission/reducers'
import role from './role/reducers'
import customer from './customer/reducers'
import contact from './contact/reducers'
import plant from './plant/reducers'
import pest from './pest/reducers'
import product from './product/reducers'
import attendance from './attendance/reducers'
import purchase from './purchase/reducers'
import activity from './activity/reducers'
import purchaseStatus from './purchase-status/reducers'
import batch from './batch/reducers'
import dashboard from './dashboard/reducers'
import settings from './settings/reducers'
import target from './target/reducers'
import area from './area/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    account,
    user,
    menu,
    permission,
    role,
    customer,
    contact,
    plant,
    pest,
    product,
    attendance,
    purchase,
    activity,
    purchaseStatus,
    batch,
    dashboard,
    settings,
    target,
    area,
  })
