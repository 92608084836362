const actions = {
  SET_STATE: 'account/SET_STATE',
  LOGIN: 'account/LOGIN',
  REGISTER: 'account/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'account/LOAD_CURRENT_ACCOUNT',
  UPDATE: 'account/UPDATE',
  LOGOUT: 'account/LOGOUT',
}

export default actions
