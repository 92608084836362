import { all } from 'redux-saga/effects'
import account from './account/sagas'
import user from './user/sagas'
import menu from './menu/sagas'
import permission from './permission/sagas'
import role from './role/sagas'
import customer from './customer/sagas'
import contact from './contact/sagas'
import plant from './plant/sagas'
import pest from './pest/sagas'
import product from './product/sagas'
import attendance from './attendance/sagas'
import purchase from './purchase/sagas'
import activity from './activity/sagas'
import purchaseStatus from './purchase-status/sagas'
import batch from './batch/sagas'
import dashboard from './dashboard/sagas'
import settings from './settings/sagas'
import target from './target/sagas'
import area from './area/sagas'

export default function* rootSaga() {
  yield all([
    account(),
    menu(),
    user(),
    permission(),
    role(),
    customer(),
    contact(),
    plant(),
    pest(),
    product(),
    attendance(),
    purchase(),
    activity(),
    purchaseStatus(),
    batch(),
    dashboard(),
    settings(),
    target(),
    area(),
  ])
}
