import apiClient from 'services/axios'

export async function list(payload) {
  return apiClient
    .get('/user', { params: payload })
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function get(payload) {
  return apiClient
    .get(`/user/${payload.user_id}`)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}
export async function store(payload) {
  return apiClient
    .post('/user', payload)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}
export async function update(payload) {
  return apiClient
    .put(`/user/${payload.id}`, payload)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function updatePassword(payload) {
  return apiClient
    .put(`/user/${payload.id}/password`, payload)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function jobTitle() {
  return apiClient
    .get(`/user/job-title`)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function destroy(payload) {
  return apiClient
    .delete(`/user/${payload.id}`)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}
