import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as services from 'services/activity'

import actions from './actions'

export function* LIST({ payload }) {
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.list, payload)
  if (response) {
    yield put({
      type: 'activity/SET_STATE',
      payload: {
        data: response,
      },
    })
  }
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* STATUS({ payload }) {
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.status, payload)
  if (response) {
    yield put({
      type: 'activity/SET_STATE',
      payload: {
        status: response,
      },
    })
  }
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* TYPE({ payload }) {
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.type, payload)
  if (response) {
    yield put({
      type: 'activity/SET_STATE',
      payload: {
        type: response,
      },
    })
  }
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DOWNLOAD({ payload, onSuccess }) {
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.download, payload)
  if (response) {
    notification.success({
      message: 'Download start',
    })
    onSuccess()
  }
  yield put({
    type: 'activity/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.STATUS, STATUS),
    takeEvery(actions.TYPE, TYPE),
    takeEvery(actions.DOWNLOAD, DOWNLOAD),
  ])
}
