import apiClient from 'services/axios'

export async function list() {
  return apiClient
    .get('/product')
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function store(payload) {
  return apiClient
    .post('/product', payload)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}

export async function update(payload) {
  return apiClient
    .put(`/product/${payload.id}`, payload)
    .then((response) => {
      if (response) {
        return true
      }
      return false
    })
    .catch((err) => console.log(err))
}
