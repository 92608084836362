import apiClient from 'services/axios'

export async function list(payload) {
  return apiClient
    .get('/target', { params: payload })
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

// export async function download(payload) {
//   return apiClient
//     .post('/attendance/download', payload, { responseType: 'blob' })
//     .then((response) => {
//       if (response) {
//         const url = window.URL.createObjectURL(new Blob([response.data]))
//         const link = document.createElement('a')
//         link.href = url
//         const fileNameHeader = 'content-disposition'
//         const suggestedFileName = response.headers[fileNameHeader]
//           .split('filename=')[1]
//           .split(';')[0]

//         link.setAttribute('download', suggestedFileName)
//         document.body.appendChild(link)
//         link.click()

//         return true
//       }
//       return false
//     })
//     .catch((err) => console.log(err))
// }

export default list
