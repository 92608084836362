import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as services from 'services/contact'
import actions from './actions'

export function* LIST() {
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.list)
  if (response) {
    yield put({
      type: 'contact/SET_STATE',
      payload: {
        data: response,
      },
    })
  }
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* STORE({ payload, onSuccess }) {
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.store, payload)
  if (response) {
    yield put({
      type: 'contact/LIST',
    })
    notification.success({
      message: 'Create contact Success',
    })
    onSuccess()
  }
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE({ payload, onSuccess }) {
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.update, payload)
  if (response) {
    yield put({
      type: 'contact/LIST',
    })
    notification.success({
      message: 'Update contact Success',
    })
    onSuccess()
  }
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* DOWNLOAD() {
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(services.download)
  if (response) {
    notification.success({
      message: 'Download start',
    })
  }
  yield put({
    type: 'contact/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.STORE, STORE),
    takeEvery(actions.DOWNLOAD, DOWNLOAD),
  ])
}
