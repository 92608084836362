import { all, takeEvery, put, call } from 'redux-saga/effects'
import list from 'services/dashboard'
import actions from './actions'

export function* LIST() {
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(list)
  if (response) {
    yield put({
      type: 'dashboard/SET_STATE',
      payload: {
        data: response,
      },
    })
  }
  yield put({
    type: 'dashboard/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LIST, LIST)])
}
