import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'
import ACL from 'components/ACL'

const routes = [
  // VB:REPLACE-START:ROUTER-CONFIG
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
    bypass: true,
  },

  {
    path: '/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
    bypass: true,
  },

  // VB:REPLACE-END:ROUTER-CONFIG
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
    bypass: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
    bypass: true,
  },
  // {
  //   path: '/auth/register',
  //   Component: lazy(() => import('pages/auth/register')),
  //   exact: true,
  // },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
    bypass: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
    bypass: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
    bypass: true,
  },

  // job

  {
    path: '/activity',
    Component: lazy(() => import('pages/activity')),
    exact: true,
  },
  {
    path: '/attendance',
    Component: lazy(() => import('pages/attendance')),
    exact: true,
  },
  {
    path: '/purchase-order',
    Component: lazy(() => import('pages/purchase')),
    exact: true,
  },
  {
    path: '/plant',
    Component: lazy(() => import('pages/plant')),
    exact: true,
  },
  {
    path: '/pest',
    Component: lazy(() => import('pages/pest')),
    exact: true,
  },
  {
    path: '/product',
    Component: lazy(() => import('pages/product')),
    exact: true,
  },
  {
    path: '/batch',
    Component: lazy(() => import('pages/batch')),
    exact: true,
  },
  {
    path: '/customer',
    Component: lazy(() => import('pages/customer')),
    exact: true,
  },
  {
    path: '/contact',
    Component: lazy(() => import('pages/contact')),
    exact: true,
  },
  {
    path: '/user',
    Component: lazy(() => import('pages/user')),
    exact: true,
  },
  {
    path: '/role',
    Component: lazy(() => import('pages/role')),
    exact: true,
  },
  {
    path: '/target',
    Component: lazy(() => import('pages/target')),
    exact: true,
  },
]

const mapStateToProps = ({ settings, menu }) => ({
  routerAnimation: settings.routerAnimation,
  menu,
})

const Router = ({ history, routerAnimation, menu }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* VB:REPLACE-NEXT-LINE:ROUTER-REDIRECT */}
                    <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
                    {routes.map(({ path, Component, exact, bypass }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <ACL
                                  roles={menu.menuData.find((x) => x.url === path)?.permission}
                                  redirect
                                  bypass={bypass}
                                >
                                  <Component />
                                </ACL>
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
