import apiClient from 'services/axios'

export async function list(payload) {
  return apiClient
    .get('/area', { params: payload })
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
}

export default list
