const actions = {
  SET_STATE: 'user/SET_STATE',
  LIST: 'user/LIST',
  LIST_SELECT: 'user/LIST_SELECT',
  LIST_JOB_TITLE: 'user/LIST_JOB_TITLE',
  GET: 'user/GET',
  STORE: 'user/STORE',
  UPDATE: 'user/UPDATE',
  UPDATE_PASSWORD: 'user/UPDATE_PASSWORD',
  DESTROY: 'user/DESTROY',
}

export default actions
